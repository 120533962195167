import logo from './logo.svg';
import './App.css';
import Container from './Pages/Container';
import Enroll from './Pages/Enroll';
function App() {
  
  return (
    <div className="App">
     <Container/>
    </div>
  );
}

export default App;
